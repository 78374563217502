.buttonaction{
    display: flex;
    justify-content: flex-end;
    gap:8px;
}

.buttonColor{
     border-radius: 18px;
     background:#21DA8C;
     color:whitesmoke;
     font-weight:600 ;
    }