.dailogBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px 24px;

  position: absolute;
  width: 562px;
  height: 592px;
  left: 402px;
  top: 137px;

  background: #ffffff;
  /* Shadow / 8 */

  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}
.demo {
  width: 150px;
  /* padding-top: 32px !important; */
}

.demo .MuiOutlinedInput-root {
  border-radius: 4px !important;
}

.Rect5{
  position: absolute;
  width:136px;
  height: 32px;
  left: 0%;
  top: 0%;
  background: #FFFFFF;
   /* border: 1px solid #D2D2D2, */
  box-sizing: border-box;
  
}
.rect5Text {
  position: absolute;
  width: 136px;
  height: 32px;
  left: 15%;
  top: 20%;
  max-width:100%;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height */
  color: #8A8A8A;
};

.MuiListSubheader-root{
  color:rgba(160, 160, 160, 1)!important;
  font-weight: 700!important;
  font-size: 12px!important;
}
.MuiListSubheader{
  color:rgba(160, 160, 160, 1)!important;
  font-weight: 700!important;
  font-size: 12px!important;
}
#campaign-alert {
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  color: #FF9800;
  height: 40px;

  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  /* warning / lightBg */

  background: #fff5e5;
  border-radius: 4px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 10px;
}

.MuiOutlinedInput-input.Mui-disabled {
  background-color: #D2D2D2!important;
  padding: 6px 14px !important;
}
