.highlightheading{
    width: 185px;
    height: 22px;
    top:144px;
    left:96px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    padding-right: 45px;
    /* identical to box height */
    
    text-transform: uppercase;
    color: #595959;
    
    
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.Heading{
    color: #595959;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    padding-right: 45px;
}
.body{
    color: #595959;
    font-weight: 700;
font-size: 42px;
padding-right: 45px;
}
.bottom{
    
    font-weight: 700;
font-size: 18px;
line-height: 22px;
color: #595959;
opacity: 0.8;
padding-right: 45px;
padding-bottom: -20%;
}

.maindiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 95px;
    border-radius: 24px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    padding-right: 45px;
}
.highlightnumbers{
    width: 69px;
    height: 51px;
    
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 51px;
    padding-right: 45px;
    
    color: #595959;
   
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    flex-grow: 0;
}

.highlightbottom{
    width: 193px;
    height: 22px;
    
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    color: #595959;
    opacity: 0.6;
    /* Inside auto layout */ 
    flex: none;
    order: 2;
    flex-grow: 0;
    padding-right: 45px;
}

.percentage {
    font-weight: 700;
    color: rgba(22, 102, 25, 1);
    font-size: 12px;   
    padding-right: 45px; 
}

.percentage-condition {
    font-weight: 700;
    color: #FF0000;
    font-size: 12px;   
    padding-right: 45px; 
}
