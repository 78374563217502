.dropdownLayout{
    width: 220px;
    height: 40px;
    position: relative;
    display: inline-block;
    padding-left: 0%;
}


.act_like_dropdown{
    z-index: 100;
    float: right !important;
    position: relative;
    min-width: 100%;
    margin: 2px;
    padding:10px;
}

.right_close_button{
    float: right;
    margin: 5px;
}
.MuiFormControlLabel-root{
    margin: 0 !important;
}

.DateButton span p{
    text-align: left;
    margin:0px !important;
    padding:1px
}

.DateButton{
    width:150px;
}

.DateButton:hover{
    border-color: rgb(24 22 22);
}
.MuiOutlinedInput-adornedEnd{
    padding-right: 2px!important;
}

.MuiOutlinedInput-inputAdornedEnd{
    padding-left: 7px!important;
}
.MuiButton-outlined.Mui-disabled{
    background-color: #D2D2D2!important;
}
/* .MuiTypography-root{
    font-size: 14px;
} */

.date-picker .MuiPopover-paper{
    width:368px !important;
    height: 192px !important;
    /* left: 1020px !important;
    top:45px !important; */
    margin-top: 10px;
    padding: 16px !important;
    border-radius: 16px !important;
    background: #FFF;
    box-shadow: 0px 6px 28px 5px rgba(0, 0, 0, 0.12), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 8px 9px -5px rgba(0, 0, 0, 0.20);
}

.date-picker .MuiInputBase-input{
    color: var(--text-primary, rgba(0, 0, 0, 0.87)) !important;
}