.App {
  padding: 32px;
  font-family: 'Nunito Sans'!important;
  background-color: #FBFBFB!important;
  z-index: 100;
  position: relative;
  gap:"31px";
}
.MuiTypography-root{
  font-family: 'Nunito Sans'!important;
  color: #595959;
}
.buttongroupactive{
  color:"#D3F8E8",
}
/* .Mui-selected{
  color: #595959!important;
  font-weight: 700!important;
} */
.circle{
  border-radius: 40px!important;
  position: absolute;
  background: linear-gradient(0deg, rgba(55, 234, 158, 0.08), rgba(55, 234, 158, 0.08)), #FFFFFF!important;
/*   opacity: 0.9!important; */
}
.midHeading{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.topheading{
  gap: 24px;
  display: flex;
  align-items: center;
}
/* .Toastify__toast{
  z-index: 150;
} */

.headerscroll{
  display:flex;
  justify-content:space-between;
  margin-top:16px;
  overflow: scroll;
}
.headerscroll::-webkit-scrollbar {
  width: 0px;
  height: 0px ;
  background: transparent; /* make scrollbar transparent */
}
.active{
  width: 42px;
height: 15px;

font-family: 'Proxima Nova';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 15px;
/* identical to box height */


color: #595959;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.roundShape{
  display: flex;
flex-direction: row;
align-items: center;
padding: 5px;
/* gap: 8px; */

width: 46px;
height: 15px;

background: #21DA8C;
border-radius: 10px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.headeraction{
  display: flex;
    align-items: baseline;
    gap: 16px;

}
.headerTop{
  display: flex;
  align-items: baseline;
  justify-content: space-between;

}
.headerRight{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
  
}

/* .App-header { */
  /* background-color: #282c34;
  /* min-height: 100vh; */
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);  */
  /* color: white; */
/* } */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.imgblock{
  display: flex;
  align-items: center;
  gap:4px;
  
}


.contactReachButton{
  color: #165154;
  text-underline-position: below;
  cursor: pointer;
}
.demo{
  width: 124px;
}
#selected{
  background-color: #d2f8d2!important;
  border-radius: 18px;
}
.MuiDialogContent-root{
  overflow: hidden!important; 
 }
