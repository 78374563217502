.segment{
    box-sizing: border-box;
    
    width: 146px;
    height: 32px;
    left: 1083px;
    top: 32px;
    display: 'flex';
    /* action / disabledBg */

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 4px;

}
.divider{
    margin-top: 24px;
}
.container {
    display: 'flex'; /* or inline-flex */
    flex-direction: row-reverse;
    flex-wrap: wrap
  }

.dropdownsign{
    position: absolute;
    right: 0%;
    bottom: 21.1%;
    background: #BDBDBD;
    height: 6.935110092163086px;
    width: 12.000099182128906px;
    left: -0.000030517578125px;
    top: 2.53271484375px;
    border-radius: 0px;

}
.headerClass{
    display: flex;
    justify-content: space-between;
}

.segDate{
    display: flex;
    float: right;
    gap: 16px;
    
}

.daterange{
    box-sizing: border-box;
    
    width: 146px;
    /* height: 32px; */
    left: 1253px;
    top: 32px;
    display: 'flex';
    font-size: 14;
    text-align: "center";
    /* action / disabledBg */
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #FFFFFF;
    border-radius: 4px;
}