.attributes-container {
    display: flex;
    align-items: row;
    justify-content: space-evenly;
  }
  
  .column-container {
    margin: 30px;
  }
  
  .heading-container {
    font-weight: bold;
    width: 40%;
    margin: 50px;
    /* margin-top: 10%; */
  }
  .dialtitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .attributegrp{
    display: flex;
    gap: 16px;
  }
  .filterbody{
    gap:24px;
    display: flex;
    flex-direction: column;

  }
  .titlefilter{
    display: flex;
    gap: 2vw;
    align-items: baseline;
  }

.MuiAutocomplete-option {
  font-family: "Nunito Sans";
}