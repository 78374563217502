:root {
  /* primary color set */
  --rgt-primary-color-pale: #faffe5;
  --rgt-primary-color-light: #9bc366;
  --rgt-primary-color-main: #21DA8C;
  --rgt-primary-color-dark: #5b7d2c;
  /* secondary color set */
  --rgt-secondary-color-pale: #ffd7db;
  --grt-secondary-color-light: #b23939;
  --rgt-secondary-color-main: #ff5252;
  --rgt-secondary-color-dark: #b23939;

  /* universal background */
  --rgt-background-color: #ffffff;
  /* table header color */
  --rgt-header-background: #faffe5;
  /*shadow color*/
  --rgt-shadow-color: #82b4401a;
  /* --rgt-border- color: #C2C9D1;  */
  --rgt-border-color: rgba(0, 0, 0, 0.12);
  --rgt-button-color: var(--rgt-primary-color-main);
  --rgt-color1: #ffff;
  /* For disabled and unselectable values */
  --rgt-color2: #00000050;
  /* For overlay color */
  --rgt-color3: var(--rgt-primary-color-main);
  /* for highlight */
  --rgt-highlight: yellow;
  /* for hover on list item */
  --rgt-color5: #f5f5f5;
  /* border specs */
  --rgt-border: 1px solid var(--rgt-border-color);
}
/* .infinite-scroll-component__outerdiv{
  overflow: auto;
} */
/* Toast */

.Toastify__toast-container {

  min-width: 320px;

}

.Toastify__toast--success {
  background: #edf7ed !important;
  color: #4CAF50;

}

.Toastify__close-button--success {
  color: #4CAF50 !important;
  opacity: 1;
}

.Toastify__toast--warning {
  background: #fff6d1 !important;
  color: #f5c60a;

}

.Toastify__close-button--warning {
  color: #f5c60a !important;
  opacity: 1;
}


.Toastify__toast--error {
  background: #fceceb !important;
  color: #F44336;

}

.Toastify__close-button--error {
  color: #F44336 !important;
  opacity: 1;
}


.name{
  color: pink;
}
.input-row{
  
  width: 100%;
  padding:0 0 0 32px;
  display: inline-flex;
}
/* .input-row >*{
  margin-right: 150px;
} */
.flex-container>.button-section{
  width: 160px;
  margin-right: 24px;
}

.selectAll {
  /* border: 1px solid black; */
  margin: 0px; 
  position: absolute;
  top: 30px;
  z-index: 10; 
  height: 28px;
  line-height: 28px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #21DA8C;
  text-align: center;
  color: #21DA8C;
  width: 99.7%;
  font-size: 12px;
}

.bolderSelectAll{
  cursor: pointer;
  padding-left: 1rem;
  text-decoration: underline;
}